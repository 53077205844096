

<template >
  <div class="form-back">
    <div class="form-text">
      <h2>registrate y participa <br />por increibles promociones</h2>
      <p>
        ¿Querés ganarte merchandising, entradas para eventos y Gancia? ¡Dejanos
        tu mail para participar!
      </p>
    </div>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show" class="form-body">
      <div class="form-nombre">
        <b-form-group
          id="input-group-1"
          class="col-md-6"
          label=""
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="form.name"
            type="text"
            placeholder="Nombre"
            required
            class="form-input"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-5"
          class="col-md-6"
          label=""
          label-for="input-5"
        >
          <b-form-input
            id="input-5"
            v-model="form.apellido"
            type="text"
            placeholder="Apellido"
            required
            class="form-input"
          ></b-form-input>
        </b-form-group>
      </div>

      <b-form-group id="input-group-2" label="" label-for="input-2">
        <b-form-input
          id="input-2"
          v-model="form.mail"
          placeholder="Email"
          required
          class="form-input"
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-3" label="" label-for="input-3">
        <b-form-select
          id="input-3"
          v-model="form.provincia"
          :options="provincia"
          required
        ></b-form-select>
      </b-form-group>

      <b-form-group id="input-group-4" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          v-model="form.checked"
          id="checkboxes-4"
          :aria-describedby="ariaDescribedby"
        >
          <b-form-checkbox value="me"
            >He leído y acepto las<a href="#" class="form-bases">
              bases y condiciones, términos y condiciones, politicas de
              privacidad</a
            >
            y activaciones de Marketing</b-form-checkbox
          >
        </b-form-checkbox-group>
      </b-form-group>
      <div class="form-center">
        <b-button type="submit" variant="primary" class="form-btn"
          >Enviar</b-button
        >
      </div>
    </b-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        email: "",
        name: "",
        apellido: "",
        provincia: null,
        checked: [],
      },
      provincia: [
        { text: "Provincia", value: "null" },
        "Buenos Aires",
        "Catamarca",
        "Ciudad Autónoma de Buenos Aires",
        "Chaco",
        "Chubut",
        "Córdoba",
        "Chubut",
        "Corrientes",
        "Entre Ríos",
        "Formosa",
        "Jujuy",
        "La Pampa",
        "La Rioja",
        "Mendoza",
        "Misiones",
        "Neuquén",
        "Río Negro",
        "Salta",
        "San Juan",
        "San Luis",
        "Santa Cruz",
        "Santa Fe",
        "Santiago del Estero",
        "Tierra del Fuego, Antártida e Islas del Atlántico Sur",
        "Tucumán",
      ],
      show: true,
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>