<template>
  <div class="back-recetas">
    <div class="recetario" v-scrollanimation>
      <h2>Recetas para disfrutar un Clásico Argentino.</h2>
    </div>
    <div class="recetas">
      <div class="autor" v-scrollanimation>
        <h4>
          by<br />
          MONA GALLOSI
        </h4>
      </div>

      <div
        class="trago"
        @mouseover="BurbujasdeAmor.hover = true"
        @mouseleave="BurbujasdeAmor.hover = false"
        v-scrollanimation
      >
        <transition name="fade">
          <div class="capa-overlay" v-if="BurbujasdeAmor.hover">
            <h4 class="trago-titulo" v-if="BurbujasdeAmor.hover">
              {{ BurbujasdeAmor.titulo }}
            </h4>
            <div class="receta-contendor">
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ BurbujasdeAmor.ingrediente1 }}
                </p>
                <p class="ingredientes-right">
                  {{ BurbujasdeAmor.medida1 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ BurbujasdeAmor.ingrediente2 }}
                </p>
                <p class="ingredientes-right">
                  {{ BurbujasdeAmor.medida2 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ BurbujasdeAmor.ingrediente2 }}
                </p>
                <p class="ingredientes-right">
                  {{ BurbujasdeAmor.medida3 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ BurbujasdeAmor.ingrediente4 }}
                </p>
                <p class="ingredientes-right">
                  {{ BurbujasdeAmor.medida4 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ BurbujasdeAmor.ingrediente5 }}
                </p>
                <p class="ingredientes-right">
                  {{ BurbujasdeAmor.medida5 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ BurbujasdeAmor.ingrediente6 }}
                </p>
                <p class="ingredientes-right">
                  {{ BurbujasdeAmor.medida6 }}
                </p>
              </div>
            </div>
          </div>
        </transition>
        <img :src="BurbujasdeAmor.imagen" alt="" class="imagen" />
      </div>
      <div
        class="trago"
        v-scrollanimation
        @mouseover="LunadeMiel.hover = true"
        @mouseleave="LunadeMiel.hover = false"
      >
        <transition name="fade">
          <div class="capa-overlay" v-if="LunadeMiel.hover">
            <h4 class="trago-titulo" v-if="LunadeMiel.hover">
              {{ LunadeMiel.titulo }}
            </h4>
            <div class="receta-contendor">
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ LunadeMiel.ingrediente1 }}
                </p>
                <p class="ingredientes-right">
                  {{ LunadeMiel.medida1 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ LunadeMiel.ingrediente2 }}
                </p>
                <p class="ingredientes-right">
                  {{ LunadeMiel.medida2 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ LunadeMiel.ingrediente2 }}
                </p>
                <p class="ingredientes-right">
                  {{ LunadeMiel.medida3 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ LunadeMiel.ingrediente4 }}
                </p>
                <p class="ingredientes-right">
                  {{ LunadeMiel.medida4 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ LunadeMiel.ingrediente5 }}
                </p>
                <p class="ingredientes-right">
                  {{ LunadeMiel.medida5 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ LunadeMiel.ingrediente6 }}
                </p>
                <p class="ingredientes-right">
                  {{ LunadeMiel.medida6 }}
                </p>
              </div>
            </div>
          </div>
        </transition>
        <img :src="LunadeMiel.imagen" alt="" class="imagen" />
      </div>
      <div
        class="trago"
        v-scrollanimation
        @mouseover="LaPuertadeAnana.hover = true"
        @mouseleave="LaPuertadeAnana.hover = false"
      >
        <transition name="fade">
          <div class="capa-overlay" v-if="LaPuertadeAnana.hover">
            <h4 class="trago-titulo" v-if="LaPuertadeAnana.hover">
              {{ LaPuertadeAnana.titulo }}
            </h4>
            <div class="receta-contendor">
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ LaPuertadeAnana.ingrediente1 }}
                </p>
                <p class="ingredientes-right">
                  {{ LaPuertadeAnana.medida1 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ LaPuertadeAnana.ingrediente2 }}
                </p>
                <p class="ingredientes-right">
                  {{ LaPuertadeAnana.medida2 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ LaPuertadeAnana.ingrediente3 }}
                </p>
                <p class="ingredientes-right">
                  {{ LaPuertadeAnana.medida3 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ LaPuertadeAnana.ingrediente4 }}
                </p>
                <p class="ingredientes-right">
                  {{ LaPuertadeAnana.medida4 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ LaPuertadeAnana.ingrediente5 }}
                </p>
                <p class="ingredientes-right">
                  {{ LaPuertadeAnana.medida5 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ LaPuertadeAnana.ingrediente6 }}
                </p>
                <p class="ingredientes-right">
                  {{ LaPuertadeAnana.medida6 }}
                </p>
              </div>
            </div>
          </div>
        </transition>
        <img :src="LaPuertadeAnana.imagen" alt="" class="imagen" />
      </div>
      <div
        class="trago"
        v-scrollanimation
        @mouseover="MarRojo.hover = true"
        @mouseleave="MarRojo.hover = false"
      >
        <transition name="fade">
          <div class="capa-overlay" v-if="MarRojo.hover">
            <h4 class="trago-titulo" v-if="MarRojo.hover">
              {{ MarRojo.titulo }}
            </h4>
            <div class="receta-contendor">
              <div class="ingredientes">
                <p class="ingredientes-left">{{ MarRojo.ingrediente1 }}</p>
                <p class="ingredientes-right">
                  {{ MarRojo.medida1 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">{{ MarRojo.ingrediente2 }}</p>
                <p class="ingredientes-right">
                  {{ MarRojo.medida2 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">{{ MarRojo.ingrediente3 }}</p>
                <p class="ingredientes-right">
                  {{ MarRojo.medida3 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">{{ MarRojo.ingrediente4 }}</p>
                <p class="ingredientes-right">
                  {{ MarRojo.medida4 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">{{ MarRojo.ingrediente5 }}</p>
                <p class="ingredientes-right">
                  {{ MarRojo.medida5 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">{{ MarRojo.ingrediente6 }}</p>
                <p class="ingredientes-right">
                  {{ MarRojo.medida6 }}
                </p>
              </div>
            </div>
          </div>
        </transition>

        <img :src="MarRojo.imagen" alt="" class="imagen" />
      </div>
      <div
        class="trago"
        v-scrollanimation
        @mouseover="AmanecerenlaFruta.hover = true"
        @mouseleave="AmanecerenlaFruta.hover = false"
      >
        <transition name="fade">
          <div class="capa-overlay" v-if="AmanecerenlaFruta.hover">
            <h4 class="trago-titulo" v-if="AmanecerenlaFruta.hover">
              {{ AmanecerenlaFruta.titulo }}
            </h4>
            <div class="receta-contendor">
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ AmanecerenlaFruta.ingrediente1 }}
                </p>
                <p class="ingredientes-right">
                  {{ AmanecerenlaFruta.medida1 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ AmanecerenlaFruta.ingrediente2 }}
                </p>
                <p class="ingredientes-right">
                  {{ AmanecerenlaFruta.medida2 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ AmanecerenlaFruta.ingrediente3 }}
                </p>
                <p class="ingredientes-right">
                  {{ AmanecerenlaFruta.medida3 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ AmanecerenlaFruta.ingrediente4 }}
                </p>
                <p class="ingredientes-right">
                  {{ AmanecerenlaFruta.medida4 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ AmanecerenlaFruta.ingrediente5 }}
                </p>
                <p class="ingredientes-right">
                  {{ AmanecerenlaFruta.medida5 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ AmanecerenlaFruta.ingrediente6 }}
                </p>
                <p class="ingredientes-right">
                  {{ AmanecerenlaFruta.medida6 }}
                </p>
              </div>
            </div>
          </div>
        </transition>

        <img :src="AmanecerenlaFruta.imagen" alt="" class="imagen" />
      </div>
      <div class="autor" v-scrollanimation>
        <h4>
          by<br />
          MATIAS BERNAOLA
        </h4>
      </div>

      <div
        class="trago"
        v-scrollanimation
        @mouseover="GaciaPomelo.hover = true"
        @mouseleave="GaciaPomelo.hover = false"
      >
        <transition name="fade">
          <div class="capa-overlay" v-if="GaciaPomelo.hover">
            <h4 class="trago-titulo" v-if="GaciaPomelo.hover">
              {{ GaciaPomelo.titulo }}
            </h4>
            <div class="receta-contendor">
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ GaciaPomelo.ingrediente1 }}
                </p>
                <p class="ingredientes-right">
                  {{ GaciaPomelo.medida1 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ GaciaPomelo.ingrediente2 }}
                </p>
                <p class="ingredientes-right">
                  {{ GaciaPomelo.medida2 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ GaciaPomelo.ingrediente3 }}
                </p>
                <p class="ingredientes-right">
                  {{ GaciaPomelo.medida3 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ GaciaPomelo.ingrediente4 }}
                </p>
                <p class="ingredientes-right">
                  {{ GaciaPomelo.medida4 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ GaciaPomelo.ingrediente5 }}
                </p>
                <p class="ingredientes-right">
                  {{ GaciaPomelo.medida5 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ GaciaPomelo.ingrediente6 }}
                </p>
                <p class="ingredientes-right">
                  {{ GaciaPomelo.medida6 }}
                </p>
              </div>
            </div>
          </div>
        </transition>

        <img :src="GaciaPomelo.imagen" alt="" class="imagen" />
      </div>
      <div
        class="trago"
        v-scrollanimation
        @mouseover="GaciaSoda.hover = true"
        @mouseleave="GaciaSoda.hover = false"
      >
        <transition name="fade">
          <div class="capa-overlay" v-if="GaciaSoda.hover">
            <h4 class="trago-titulo" v-if="GaciaSoda.hover">
              {{ GaciaSoda.titulo }}
            </h4>
            <div class="receta-contendor">
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ GaciaSoda.ingrediente1 }}
                </p>
                <p class="ingredientes-right">
                  {{ GaciaSoda.medida1 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ GaciaSoda.ingrediente2 }}
                </p>
                <p class="ingredientes-right">
                  {{ GaciaSoda.medida2 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ GaciaSoda.ingrediente3 }}
                </p>
                <p class="ingredientes-right">
                  {{ GaciaSoda.medida3 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ GaciaSoda.ingrediente4 }}
                </p>
                <p class="ingredientes-right">
                  {{ GaciaSoda.medida4 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ GaciaSoda.ingrediente5 }}
                </p>
                <p class="ingredientes-right">
                  {{ GaciaSoda.medida5 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ GaciaSoda.ingrediente6 }}
                </p>
                <p class="ingredientes-right">
                  {{ GaciaSoda.medida6 }}
                </p>
              </div>
            </div>
          </div>
        </transition>

        <img :src="GaciaSoda.imagen" alt="" class="imagen" />
      </div>
      <div
        class="trago"
        v-scrollanimation
        @mouseover="GaciaLimaLimon.hover = true"
        @mouseleave="GaciaLimaLimon.hover = false"
      >
        <transition name="fade">
          <div class="capa-overlay" v-if="GaciaLimaLimon.hover">
            <h4 class="trago-titulo" v-if="GaciaLimaLimon.hover">
              {{ GaciaLimaLimon.titulo }}
            </h4>
            <div class="receta-contendor">
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ GaciaLimaLimon.ingrediente1 }}
                </p>
                <p class="ingredientes-right">
                  {{ GaciaLimaLimon.medida1 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ GaciaLimaLimon.ingrediente2 }}
                </p>
                <p class="ingredientes-right">
                  {{ GaciaLimaLimon.medida2 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ GaciaLimaLimon.ingrediente3 }}
                </p>
                <p class="ingredientes-right">
                  {{ GaciaLimaLimon.medida3 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ GaciaLimaLimon.ingrediente4 }}
                </p>
                <p class="ingredientes-right">
                  {{ GaciaLimaLimon.medida4 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ GaciaLimaLimon.ingrediente5 }}
                </p>
                <p class="ingredientes-right">
                  {{ GaciaLimaLimon.medida5 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ GaciaLimaLimon.ingrediente6 }}
                </p>
                <p class="ingredientes-right">
                  {{ GaciaLimaLimon.medida6 }}
                </p>
              </div>
            </div>
          </div>
        </transition>

        <img :src="GaciaLimaLimon.imagen" alt="" class="imagen" />
      </div>
      <div
        class="trago"
        v-scrollanimation
        @mouseover="GaciaPicante.hover = true"
        @mouseleave="GaciaPicante.hover = false"
      >
        <transition name="fade">
          <div class="capa-overlay" v-if="GaciaPicante.hover">
            <h4 class="trago-titulo" v-if="GaciaPicante.hover">
              {{ GaciaPicante.titulo }}
            </h4>
            <div class="receta-contendor">
              <div class="ingredientes">
                <p class="ingredientes-left">{{ GaciaPicante.ingrediente1 }}</p>
                <p class="ingredientes-right">
                  {{ GaciaPicante.medida1 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">{{ GaciaPicante.ingrediente2 }}</p>
                <p class="ingredientes-right">
                  {{ GaciaPicante.medida2 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">{{ GaciaPicante.ingrediente3 }}</p>
                <p class="ingredientes-right">
                  {{ GaciaPicante.medida3 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">{{ GaciaPicante.ingrediente4 }}</p>
                <p class="ingredientes-right">
                  {{ GaciaPicante.medida4 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">{{ GaciaPicante.ingrediente5 }}</p>
                <p class="ingredientes-right">
                  {{ GaciaPicante.medida5 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">{{ GaciaPicante.ingrediente6 }}</p>
                <p class="ingredientes-right">
                  {{ GaciaPicante.medida6 }}
                </p>
              </div>
            </div>
          </div>
        </transition>

        <img :src="GaciaPicante.imagen" alt="" class="imagen" />
      </div>
      <div
        class="trago"
        v-scrollanimation
        @mouseover="GaciaJulep.hover = true"
        @mouseleave="GaciaJulep.hover = false"
      >
        <transition name="fade">
          <div class="capa-overlay" v-if="GaciaJulep.hover">
            <h4 class="trago-titulo" v-if="GaciaJulep.hover">
              {{ GaciaJulep.titulo }}
            </h4>
            <div class="receta-contendor">
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ GaciaJulep.ingrediente1 }}
                </p>
                <p class="ingredientes-right">
                  {{ GaciaJulep.medida1 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ GaciaJulep.ingrediente2 }}
                </p>
                <p class="ingredientes-right">
                  {{ GaciaJulep.medida2 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ GaciaJulep.ingrediente3 }}
                </p>
                <p class="ingredientes-right">
                  {{ GaciaJulep.medida3 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ GaciaJulep.ingrediente4 }}
                </p>
                <p class="ingredientes-right">
                  {{ GaciaJulep.medida4 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ GaciaJulep.ingrediente5 }}
                </p>
                <p class="ingredientes-right">
                  {{ GaciaJulep.medida5 }}
                </p>
              </div>
              <div class="ingredientes">
                <p class="ingredientes-left">
                  {{ GaciaJulep.ingrediente6 }}
                </p>
                <p class="ingredientes-right">
                  {{ GaciaJulep.medida6 }}
                </p>
              </div>
            </div>
          </div>
        </transition>
        <img :src="GaciaJulep.imagen" alt="" class="imagen" />
      </div>
    </div>
  </div>
</template>
<script>
import MatiasBernola from "./MatiasBernola";
export default {
  components: {},
  data() {
    return {
      BurbujasdeAmor: {
        titulo: "Burbujas de Amor",
        ingrediente1: "Americano Gancia ",
        medida1: "90 ml",
        ingrediente2: "Jugo de limon fresco ",
        medida2: "30 ml",
        ingrediente3: "Azucar ",
        medida3: "1 cucharada",
        ingrediente4: "Decoración ",
        medida4: "Piel de limón",
        ingrediente5: "Metodo de preparación ",
        medida5: "Batido",
        ingrediente6: " ",
        medida6: "",
        imagen: "img/tragos/BurbujasdeAmor.jpg",

        isHidden: false,
        hover: false,
      },
      LunadeMiel: {
        titulo: "Luna de miel",
        ingrediente1: "Americano Gancia ",
        medida1: "90 ml",
        ingrediente2: "Jugo de durazno ",
        medida2: "60 ml",
        ingrediente3: "Jugo de limon ",
        medida3: "10 ml",
        ingrediente4: "Almibar simple  ",
        medida4: "10 ml",
        ingrediente5: "Decoración ",
        medida5: "Rama de tomillo fresco",
        ingrediente6: "Metodo de preparación ",
        medida6: "Batido",
        imagen: "img/tragos/LunadeMiel.jpg",

        isHidden: false,
        hover: false,
      },
      LaPuertadeAnana: {
        titulo: "La puerta de Anana",
        ingrediente1: "Americano Gancia ",
        medida1: "40 ml",
        ingrediente2: "Pineral ",
        medida2: "20 ml",
        ingrediente3: "Gin Bombay ",
        medida3: "20 ml",
        ingrediente4: "Decoración",
        medida4: "Cuadrado de Anana fresca",
        ingrediente5: "Metodo de preparación  ",
        medida5: "Refrescasdo",
        ingrediente6: "",
        medida6: "",
        imagen: "img/tragos/LapuertadeAnana.jpg",

        isHidden: false,
        hover: false,
      },
      MarRojo: {
        titulo: "Mar Rojo",
        ingrediente1: "Gancia Frutos Rojos",
        medida1: "45 ml",
        ingrediente2: "Jugo de sandia ",
        medida2: "45 ml",
        ingrediente3: "Sweet & Sour",
        medida3: "15 ml",
        ingrediente4: "Metodo de preparación",
        medida4: "Batido",
        ingrediente5: " ",
        medida5: "",
        ingrediente6: "",
        medida6: "",
        imagen: "img/tragos/MarRojo.jpg",

        isHidden: false,
        hover: false,
      },
      AmanecerenlaFruta: {
        titulo: "AMANECE EN LA FRUTA",
        ingrediente1: "Gancia Americano",
        medida1: "250 ml",
        ingrediente2: "Gaseosa Lima Limon",
        medida2: "600 ml",
        ingrediente3: "Sweet & Sour",
        medida3: "50 ml",
        ingrediente4: "Duranzno en medias lunas finas",
        medida4: "1 entero",
        ingrediente5: " Ciruelas en medias lunas finas",
        medida5: "2 entero",
        ingrediente6: "Limon en medias lunas finas",
        medida6: "1 entero",
        imagen: "img/tragos/AmanecerenlaFruta.jpg",

        isHidden: false,
        hover: false,
      },
      GaciaPomelo: {
        titulo: "GANCIA CON POMELO",
        ingrediente1: "Americano Gancia ",
        medida1: "60 ml",
        ingrediente2: "Jugo de pomelo exprimido ",
        medida2: "120 ml",
        ingrediente3: "Decoración ",
        medida3: "Rodaja de Pomelo rosado",
        ingrediente4: "Metodo de preparación ",
        medida4: "Directo",
        ingrediente5: " ",
        medida5: "",
        ingrediente6: " ",
        medida6: "",
        imagen: "img/tragos/GaciaPomelo.jpg",

        isHidden: false,
        hover: false,
      },
      GaciaSoda: {
        titulo: "GANCIA CON SODA",
        ingrediente1: "Americano Gancia ",
        medida1: "60 ml",
        ingrediente2: "Soda",
        medida2: "120 ml",
        ingrediente3: "Decoración ",
        medida3: "Rodaja de Lima y Limón",
        ingrediente4: "Metodo de preparación ",
        medida4: "Directo",
        ingrediente5: " ",
        medida5: "",
        ingrediente6: " ",
        medida6: "",
        imagen: "img/tragos/GaciaSoda.jpg",

        isHidden: false,
        hover: false,
      },
      GaciaLimaLimon: {
        titulo: "GANCIA CON LIMA LIMÓN ",
        ingrediente1: "Americano Gancia ",
        medida1: "60 ml",
        ingrediente2: "Gaseosa Lima Limón ",
        medida2: "120 ml",
        ingrediente3: "Decoración ",
        medida3: "Rodaja de Limón",
        ingrediente4: "Metodo de preparación ",
        medida4: "Directo",
        ingrediente5: " ",
        medida5: "",
        ingrediente6: " ",
        medida6: "",
        imagen: "img/tragos/GanciaLimaLimon.jpg",

        isHidden: false,
        hover: false,
      },
      GaciaPicante: {
        titulo: "GANCIA PICANTE",
        ingrediente1: "Americano Gancia ",
        medida1: "60 ml",
        ingrediente2: "Miel de jengibre ",
        medida2: "20 ml",
        ingrediente3: "Limón",
        medida3: "25 ml",
        ingrediente4: "Decoración ",
        medida4: "Moler pimienta negra",
        ingrediente5: "Metodo de preparación ",
        medida5: "Batido",
        ingrediente6: " ",
        medida6: "",
        imagen: "img/tragos/GanciaPicante.jpg",

        isHidden: false,
        hover: false,
      },
      GaciaJulep: {
        titulo: "GANCIA JULEP",
        ingrediente1: "Americano Gancia ",
        medida1: "60 ml",
        ingrediente2: "Jugo de pomelo exprimido",
        medida2: "120 ml",
        ingrediente3: "Menta",
        medida3: "2 ramas o 7 hojas",
        ingrediente4: "Decoración ",
        medida4: "Menta + Pomelo rosado",
        ingrediente5: "Metodo de preparación ",
        medida5: "Directo",
        ingrediente6: " ",
        medida6: "",
        imagen: "img/tragos/GanciaJulep.jpg",

        isHidden: false,
        hover: false,
      },
    };
  },
};
</script>
>
<style scoped>
.back-recetas {
  box-sizing: border-box;
}
.recetario {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.recetario h2 {
  color: #df3e3e;

  text-align: center;
  width: 40%;
}
.recetas {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0px;
  padding: 0;
}

.autor {
  background: #df3e3e;
  color: #fff;
  font-size: 20px;
  text-align: center;
  width: 33.33%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}
.trago {
  width: 33.33%;
  cursor: pointer;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}
.capa-overlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
}

.trago-titulo {
  width: 100%;
  color: #fff;
  height: auto;
  text-align: center;
  font-size: 30px;
  padding-bottom: 50px;
}

.receta-contendor {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.ingredientes {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 50px;
}

.ingredientes-left {
  text-align: left;
}
.ingredientes-right {
  text-align: right;
}

.recetas p {
  color: #fff;
  font-family: lato;
  font-weight: 500;
}

.imagen {
  width: 100%;
  z-index: -1;
}
/*animacion*/
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@media (min-width: 1750px) {
  .ingredientes {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 100px;
    padding-right: 100px;
    font-size: 16px;
  }
  .recetario h2 {
    color: #df3e3e;
    font-size: 50px;
    text-align: center;
    width: 50%;
  }
  .trago-titulo {
    width: 100%;
    color: #fff;
    height: auto;
    text-align: center;
    padding-bottom: 50px;
    font-size: 30px;
  }
}

@media (max-width: 1020px) {
  .recetario {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

  .recetario h2 {
    color: #df3e3e;

    text-align: center;
    width: 60%;
  }
  .recetas {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0px;
    padding: 0;
  }

  .autor {
    background: #df3e3e;
    color: #fff;
    font-size: 20px;
    text-align: center;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
  }
  .trago {
    width: 50%;
    cursor: pointer;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
  }
}

@media (max-width: 778px) {
  .recetario {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

  .recetario h2 {
    color: #df3e3e;
    font-size: 25px;
    text-align: center;
    width: 90%;
  }
  .recetas {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0px;
    padding: 0;
  }

  .autor {
    background: #df3e3e;
    color: #fff;
    font-size: 20px;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    padding-bottom: 30px;
  }
  .trago {
    width: 100%;
    cursor: pointer;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
  }
  .ingredientes {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
  }
}

/*Animaciones*/
.before-entra {
  opacity: 0;
  transform: translateY(70px) scale(0.7);
  transition: all 1s cubic-bezier(0.41, 0.01, 0.57, 1.61);
}
/* 
    If the element intersects with the viewport, the before-enter class is added.
  */
.entra {
  opacity: 1;
  transform: translateY(0px);
}
</style>
