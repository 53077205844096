<template>
  <div>
    <pop-up></pop-up>
  </div>
</template>
<script>
import PopUp from "./components/popup";

export default {
  name: "home",
  bodyClass: "home-page",
  components: {
    PopUp,
  },
};
</script>
<style></style>
