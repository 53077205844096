import Vue from 'vue';
import Router from 'vue-router';
import Index from './pages/Index.vue';
import Productos from './pages/components/Productos.vue';
import CarouselSection from './pages/components/CarouselSection.vue';
import FormularioRegistro from './pages/components/FormularioRegistro.vue';
import CoktelesEmbajadores from './pages/components/CoktelesEmbajadores.vue';
import Historia from './pages/components/Historia.vue';
import Novedades from './pages/components/Novedades.vue';
import PopUp from './pages/components/popup.vue';
import MainNavbar from './layout/MainNavbar.vue';
import MainFooter from './layout/MainFooter.vue';
import Log from './pages/Log.vue';
import Tragos from './pages/Tragos.vue';
import HeaderTragos from './pages/components/HeaderTragos.vue';



Vue.use(Router);


export default new Router({
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Log,

    },
    {
      path: '/',
      name: 'home',
      components: {
        default: Index, popup: PopUp, productos: Productos, home: CarouselSection, header: MainNavbar, embajadores: CoktelesEmbajadores, historia: Historia, footer: MainFooter, form: FormularioRegistro, novedades: Novedades
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' },

      },



    },

    {
      path: '/tragos',
      name: 'tragos',
      components: {
        default: Tragos, header: MainNavbar, footer: MainFooter, headtragos: HeaderTragos,
      },

    },

    { path: "*", component: Log },

    // this is also a route record

  ],

  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

