<template >
  <navbar
    position="fixed"
    type="primary"
    :transparent="false"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
    id="navbar5"
    v-b-scrollspy:nav-scroller
    slot="header"
  >
    <template>
      <router-link v-popover:popover1 class="navbar-brand" to="/">
        <img src="/gancia.svg" class="img-logo" />
      </router-link>
    </template>

    <template slot="navbar-menu" class="o-d">
      <li class="nav-item" v-for="CarouselSection in 1" :key="CarouselSection">
        <a class="nav-link" href="#up">
          <p class="nav-scroll">HOME</p>
        </a>
      </li>
      <li class="nav-item" v-for="productos in 1" :key="productos">
        <a class="nav-link" href="#producto">
          <p class="nav-scroll">{{ Productos }}</p>
        </a>
      </li>

      <li class="nav-item o-d">
        <a
          class="nav-link"
          href="https://cheersapp.com.ar/results/brand/gancia
"
          target="_blank"
        >
          <p class="tienda-btn">
            TIENDA OFICIAL
            <img src="/carrito.svg" style="width: 25px; padding-left: 5px" />
          </p>
        </a>
      </li>
    </template>
    <template>
      <div class="o-m">
        <a
          class="nav-link"
          href="https://cheersapp.com.ar/results/brand/gancia"
          target="_blank"
        >
          <img
            src="/carrito_fondo.svg"
            style="width: 40px; margin-left: 20px"
          />
        </a>
      </div>
    </template>
  </navbar>
</template>


<script>
import { Navbar } from "@/components";
import { Popover } from "element-ui";
//import Test from "./components/test";

export default {
  name: "main-navbar",
  props: {
    transparent: Boolean,
    colorOnScroll: Number,
  },
  components: {
    Navbar,

    [Popover.name]: Popover,
  },
  methods: {
    // Convenience method to scroll a heading into view.
    // Not required for scrollspy to work
    scrollIntoView(event) {
      event.preventDefault();
      const href = event.target.getAttribute("href");
      const el = href ? document.querySelector(href) : null;
      if (el) {
        this.$refs.content.scrollTop = el.offsetTop;
      }
    },
  },
  data() {
    return {
      underline: true,
      Productos: "PRODUCTOS",

      types: [
        "custom",
        "default",
        "primary",
        "danger",
        "success",
        "warning",
        "info",
      ],
    };
  },
};
</script>

<style scoped>
.nav-scroll:visited {
  border-bottom: 3px solid #df3e3e;
  border-radius: 0px solid;
  padding-bottom: 1px !important;
}
.nav-scroll:hover {
  border-bottom: 3px solid #df3e3e;
  border-radius: 0px solid !important;
  padding-bottom: 1px !important;
}

.bg-primary {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
</style>



