<template>
  <footer class="footer" :class="{ [`footer-${type}`]: type }">
    <div class="container">
      <h2>#GANCIASIEMPRE</h2>
      <div class="flex-footer">
        <a href="https://www.instagram.com/ganciaarg" target="_blank"
          ><img src="/img/icon/instagram.svg" alt="instagram"
        /></a>
        <a href="https://www.facebook.com/Gancia/" target="_blank"
          ><img src="/img/icon/facebook.svg" alt="facebook"
        /></a>
        <a href="https://twitter.com/Ganciaarg" target="_blank"
          ><img src="/img/icon/twitter.svg" alt="twitter"
        /></a>
        <a href="https://www.youtube.com/user/gancialive" target="_blank"
          ><img src="/img/icon/youtube.svg" alt="youtube"
        /></a>
      </div>
      <div>
        <p
          type="primary"
          :transparent="false"
          :color-on-scroll="colorOnScroll"
          menu-classes="ml-auto"
          class="navbar fixed-bottom footer content container-fluid"
          id="footer5"
        >
          Copyright GANCIA | BEBER CON MODERACION. PROHIBIDA SU VENTA A MENORES
          DE 18 AÑOS.
        </p>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String,
    transparent: Boolean,
    colorOnScroll: Number,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      types: [
        "custom",
        "default",
        "primary",
        "danger",
        "success",
        "warning",
        "info",
      ],
    };
  },
};

let ubicacionPrincipal = window.pageYOffset;
window.onscroll = function () {
  let Desplazamiento = window.pageYOffset;
  if (ubicacionPrincipal >= Desplazamiento) {
    this.document.getElementById("footer5").style.bottom = "-100px";
    this.document.getElementById("navbar5").style.top = "0";
  } else {
    document.getElementById("footer5").style.bottom = "0px";
    document.getElementById("navbar5").style.top = "-100px";
  }
  ubicacionPrincipal = Desplazamiento;
};
</script>

<style scoped>
.bg-primary {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.navbar {
  padding-top: 0px;
  padding-bottom: 0px;
  min-height: 0px;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
}

#copy {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  color: #fff;
  text-align: center;
  padding-top: 0px;
  width: 100%;
}
</style>
