<template 
    >
  <div>
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      background="#000"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
      class="altura_carrusel"
    >
      <b-carousel-slide
        :img-src="img1"
        alt="First slide"
        class="slide-img lata"
      >
        <div class="carousel-caption position-carousel">
          <h1 class="slider-1">
            {{ title }}<br />
            <span
              ><span style="color: #df3e3e; padding-right: 15px">+</span
              >{{ subtitle1 }}</span
            >
          </h1>
          <p class="grad_lima">{{ graduacion1 }}</p>
          <br />
          <a class="boton-slide" :href="link1" target="_blank">{{ btn }}</a>
        </div>
      </b-carousel-slide>
      <b-carousel-slide
        class="slide-img americano"
        :img-src="img2"
        alt="Second slide americano"
      >
        <div class="carousel-caption position-carousel">
          <h1 class="slider-1">
            {{ title }}<br />
            <span>{{ subtitle2 }}</span>
          </h1>
          <p class="grad_americano">{{ graduacion2 }}</p>

          <br />
          <a class="boton-slide" href="link2" target="_blank">{{ btn }}</a>
        </div>
      </b-carousel-slide>
      <b-carousel-slide class="slide-img" :img-src="img3" alt="Third slide">
        <div class="carousel-caption1 position-carousel">
          <h1 class="slider-1">
            {{ title }}<br />
            <span>{{ subtitle3 }}</span>
          </h1>
          <p class="grad_rose">{{ graduacion3 }}</p>

          <br />
          <a class="boton-slide" :href="link3" target="_blank">{{ btn }}</a>
        </div>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>
<script>
export default {
  name: "carousel-1",
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },

  methods: {
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
  },

  props: {
    title: {
      type: String,
      default: "GANCIA",
    },
    subtitle1: {
      type: String,
      default: "LIMA LIMÓN",
    },
    subtitle2: {
      type: String,
      default: "AMERICANO",
    },
    subtitle3: {
      type: String,
      default: "FRUTOS ROJOS",
    },
    graduacion1: {
      type: String,
      default: "Grad 3.5% vol.",
    },
    graduacion2: {
      type: String,
      default: "Grad 14% vol.",
    },
    graduacion3: {
      type: String,
      default: "Grad 9.8% vol.",
    },
    btn: {
      type: String,
      default: "COMPRA ACÁ",
    },
    img1: {
      type: String,
      default: "img/gancia1.jpg",
    },
    img2: {
      type: String,
      default: "img/gancia2.jpg",
    },

    img3: {
      type: String,
      default: "img/gancia3.jpg",
    },
    link1: {
      type: String,
      default:
        "https://cheersapp.com.ar/product-detail/GANCIA-%2B-LIMA-LIM%C3%93N-310ml-PACK-x6/4",
    },
    link2: {
      type: String,
      default:
        "https://cheersapp.com.ar/product-detail/AMERICANO-GANCIA-950ml-PACK-x6/1",
    },
    link3: {
      type: String,
      default:
        "https://cheersapp.com.ar/product-detail/GANCIA-FRUTOS-ROJOS-750ml-PACK-x6/7",
    },
  },
};
</script>
<style>
</style>
