/*!

 =========================================================
 * Vue Now UI Kit - v1.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/now-ui-kit
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from 'vue';
import App from './App.vue';
// You can change this import to `import router from './starterRouter'` to quickly start development from a blank layout.
import router from './router';
import ScrolAnitamion from './directives/scrollanimation'
import VueCarousel from 'vue-carousel';
import vueRouter from 'vue-router'
import 'bootstrap';
import NowUiKit from './plugins/now-ui-kit';
import "@/assets/demo/global.css";
import VueAnalytics from 'vue-analytics';

Vue.config.productionTip = false;
Vue.directive('scrollanimation', ScrolAnitamion);
Vue.use(NowUiKit);
Vue.use(VueCarousel);
Vue.use(vueRouter);

// Configuration VueAnalytics
Vue.use(VueAnalytics, {
  id: 'UA-85175806-1',
  router
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');




