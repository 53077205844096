<template>
  <div>
    <div class="autor" v-scrollanimation>
      <h4>
        by<br />
        MATIAS BERNAOLA
      </h4>
    </div>

    <div
      class="trago"
      v-scrollanimation
      @mouseover="BurbujasdeAmor.hover = true"
      @mouseleave="BurbujasdeAmor.hover = false"
    >
      <transition name="fade">
        <div class="capa-overlay" v-if="BurbujasdeAmor.hover">
          <h4 class="trago-titulo" v-if="BurbujasdeAmor.hover">
            {{ BurbujasdeAmor.titulo }}
          </h4>
          <div class="receta-contendor">
            <div class="ingredientes">
              <p class="ingredientes-left">
                {{ BurbujasdeAmor.ingrediente1 }}
              </p>
              <p class="ingredientes-right">
                {{ BurbujasdeAmor.medida1 }}
              </p>
            </div>
            <div class="ingredientes">
              <p class="ingredientes-left">
                {{ BurbujasdeAmor.ingrediente2 }}
              </p>
              <p class="ingredientes-right">
                {{ BurbujasdeAmor.medida2 }}
              </p>
            </div>
            <div class="ingredientes">
              <p class="ingredientes-left">
                {{ BurbujasdeAmor.ingrediente2 }}
              </p>
              <p class="ingredientes-right">
                {{ BurbujasdeAmor.medida3 }}
              </p>
            </div>
            <div class="ingredientes">
              <p class="ingredientes-left">
                {{ BurbujasdeAmor.ingrediente4 }}
              </p>
              <p class="ingredientes-right">
                {{ BurbujasdeAmor.medida4 }}
              </p>
            </div>
            <div class="ingredientes">
              <p class="ingredientes-left">
                {{ BurbujasdeAmor.ingrediente5 }}
              </p>
              <p class="ingredientes-right">
                {{ BurbujasdeAmor.medida5 }}
              </p>
            </div>
            <div class="ingredientes">
              <p class="ingredientes-left">
                {{ BurbujasdeAmor.ingrediente6 }}
              </p>
              <p class="ingredientes-right">
                {{ BurbujasdeAmor.medida6 }}
              </p>
            </div>
          </div>
        </div>
      </transition>

      <img :src="BurbujasdeAmor.imagen" alt="" class="imagen" />
    </div>
    <div
      class="trago"
      v-scrollanimation
      @mouseover="LunadeMiel.hover = true"
      @mouseleave="LunadeMiel.hover = false"
    >
      <transition name="fade">
        <div class="capa-overlay" v-if="LunadeMiel.hover">
          <h4 class="trago-titulo" v-if="LunadeMiel.hover">
            {{ LunadeMiel.titulo }}
          </h4>
          <div class="receta-contendor">
            <div class="ingredientes">
              <p class="ingredientes-left">
                {{ LunadeMiel.ingrediente1 }}
              </p>
              <p class="ingredientes-right">
                {{ LunadeMiel.medida1 }}
              </p>
            </div>
            <div class="ingredientes">
              <p class="ingredientes-left">
                {{ LunadeMiel.ingrediente2 }}
              </p>
              <p class="ingredientes-right">
                {{ LunadeMiel.medida2 }}
              </p>
            </div>
            <div class="ingredientes">
              <p class="ingredientes-left">
                {{ LunadeMiel.ingrediente2 }}
              </p>
              <p class="ingredientes-right">
                {{ LunadeMiel.medida3 }}
              </p>
            </div>
            <div class="ingredientes">
              <p class="ingredientes-left">
                {{ LunadeMiel.ingrediente4 }}
              </p>
              <p class="ingredientes-right">
                {{ LunadeMiel.medida4 }}
              </p>
            </div>
            <div class="ingredientes">
              <p class="ingredientes-left">
                {{ LunadeMiel.ingrediente5 }}
              </p>
              <p class="ingredientes-right">
                {{ LunadeMiel.medida5 }}
              </p>
            </div>
            <div class="ingredientes">
              <p class="ingredientes-left">
                {{ LunadeMiel.ingrediente6 }}
              </p>
              <p class="ingredientes-right">
                {{ LunadeMiel.medida6 }}
              </p>
            </div>
          </div>
        </div>
      </transition>

      <img :src="LunadeMiel.imagen" alt="" class="imagen" />
    </div>
    <div
      class="trago"
      v-scrollanimation
      @mouseover="LaPuertadeAnana.hover = true"
      @mouseleave="LaPuertadeAnana.hover = false"
    >
      <transition name="fade">
        <div class="capa-overlay" v-if="LaPuertadeAnana.hover">
          <h4 class="trago-titulo" v-if="LaPuertadeAnana.hover">
            {{ LaPuertadeAnana.titulo }}
          </h4>
          <div class="receta-contendor">
            <div class="ingredientes">
              <p class="ingredientes-left">
                {{ LaPuertadeAnana.ingrediente1 }}
              </p>
              <p class="ingredientes-right">
                {{ LaPuertadeAnana.medida1 }}
              </p>
            </div>
            <div class="ingredientes">
              <p class="ingredientes-left">
                {{ LaPuertadeAnana.ingrediente2 }}
              </p>
              <p class="ingredientes-right">
                {{ LaPuertadeAnana.medida2 }}
              </p>
            </div>
            <div class="ingredientes">
              <p class="ingredientes-left">
                {{ LaPuertadeAnana.ingrediente3 }}
              </p>
              <p class="ingredientes-right">
                {{ LaPuertadeAnana.medida3 }}
              </p>
            </div>
            <div class="ingredientes">
              <p class="ingredientes-left">
                {{ LaPuertadeAnana.ingrediente4 }}
              </p>
              <p class="ingredientes-right">
                {{ LaPuertadeAnana.medida4 }}
              </p>
            </div>
            <div class="ingredientes">
              <p class="ingredientes-left">
                {{ LaPuertadeAnana.ingrediente5 }}
              </p>
              <p class="ingredientes-right">
                {{ LaPuertadeAnana.medida5 }}
              </p>
            </div>
            <div class="ingredientes">
              <p class="ingredientes-left">
                {{ LaPuertadeAnana.ingrediente6 }}
              </p>
              <p class="ingredientes-right">
                {{ LaPuertadeAnana.medida6 }}
              </p>
            </div>
          </div>
        </div>
      </transition>

      <img :src="LaPuertadeAnana.imagen" alt="" class="imagen" />
    </div>
    <div
      class="trago"
      v-scrollanimation
      @mouseover="MarRojo.hover = true"
      @mouseleave="MarRojo.hover = false"
    >
      <transition name="fade">
        <div class="capa-overlay" v-if="MarRojo.hover">
          <h4 class="trago-titulo" v-if="MarRojo.hover">
            {{ MarRojo.titulo }}
          </h4>
          <div class="receta-contendor">
            <div class="ingredientes">
              <p class="ingredientes-left">{{ MarRojo.ingrediente1 }}</p>
              <p class="ingredientes-right">
                {{ MarRojo.medida1 }}
              </p>
            </div>
            <div class="ingredientes">
              <p class="ingredientes-left">{{ MarRojo.ingrediente2 }}</p>
              <p class="ingredientes-right">
                {{ MarRojo.medida2 }}
              </p>
            </div>
            <div class="ingredientes">
              <p class="ingredientes-left">{{ MarRojo.ingrediente3 }}</p>
              <p class="ingredientes-right">
                {{ MarRojo.medida3 }}
              </p>
            </div>
            <div class="ingredientes">
              <p class="ingredientes-left">{{ MarRojo.ingrediente4 }}</p>
              <p class="ingredientes-right">
                {{ MarRojo.medida4 }}
              </p>
            </div>
            <div class="ingredientes">
              <p class="ingredientes-left">{{ MarRojo.ingrediente5 }}</p>
              <p class="ingredientes-right">
                {{ MarRojo.medida5 }}
              </p>
            </div>
            <div class="ingredientes">
              <p class="ingredientes-left">{{ MarRojo.ingrediente6 }}</p>
              <p class="ingredientes-right">
                {{ MarRojo.medida6 }}
              </p>
            </div>
          </div>
        </div>
      </transition>

      <img :src="MarRojo.imagen" alt="" class="imagen" />
    </div>
    <div
      class="trago"
      v-scrollanimation
      @mouseover="AmanecerenlaFruta.hover = true"
      @mouseleave="AmanecerenlaFruta.hover = false"
    >
      <transition name="fade">
        <div class="capa-overlay" v-if="AmanecerenlaFruta.hover">
          <h4 class="trago-titulo" v-if="AmanecerenlaFruta.hover">
            {{ AmanecerenlaFruta.titulo }}
          </h4>
          <div class="receta-contendor">
            <div class="ingredientes">
              <p class="ingredientes-left">
                {{ AmanecerenlaFruta.ingrediente1 }}
              </p>
              <p class="ingredientes-right">
                {{ AmanecerenlaFruta.medida1 }}
              </p>
            </div>
            <div class="ingredientes">
              <p class="ingredientes-left">
                {{ AmanecerenlaFruta.ingrediente2 }}
              </p>
              <p class="ingredientes-right">
                {{ AmanecerenlaFruta.medida2 }}
              </p>
            </div>
            <div class="ingredientes">
              <p class="ingredientes-left">
                {{ AmanecerenlaFruta.ingrediente3 }}
              </p>
              <p class="ingredientes-right">
                {{ AmanecerenlaFruta.medida3 }}
              </p>
            </div>
            <div class="ingredientes">
              <p class="ingredientes-left">
                {{ AmanecerenlaFruta.ingrediente4 }}
              </p>
              <p class="ingredientes-right">
                {{ AmanecerenlaFruta.medida4 }}
              </p>
            </div>
            <div class="ingredientes">
              <p class="ingredientes-left">
                {{ AmanecerenlaFruta.ingrediente5 }}
              </p>
              <p class="ingredientes-right">
                {{ AmanecerenlaFruta.medida5 }}
              </p>
            </div>
            <div class="ingredientes">
              <p class="ingredientes-left">
                {{ AmanecerenlaFruta.ingrediente6 }}
              </p>
              <p class="ingredientes-right">
                {{ AmanecerenlaFruta.medida6 }}
              </p>
            </div>
          </div>
        </div>
      </transition>
      <img :src="AmanecerenlaFruta.imagen" alt="" class="imagen" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      BurbujasdeAmor: {
        titulo: "Burbujas de Amor",
        ingrediente1: "Americano Gancia ",
        medida1: "90 ml",
        ingrediente2: "Jugo de limon fresco ",
        medida2: "30 ml",
        ingrediente3: "Azucar ",
        medida3: "1 cucharada",
        ingrediente4: "Decoración ",
        medida4: "Piel de limón",
        ingrediente5: "Metodo de preparación ",
        medida5: "Batido",
        ingrediente6: " ",
        medida6: "",
        imagen: "img/tragos/BurbujasdeAmor.jpg",

        isHidden: false,
        hover: false,
      },
      LunadeMiel: {
        titulo: "Luna de miel",
        ingrediente1: "Americano Gancia ",
        medida1: "90 ml",
        ingrediente2: "Jugo de durazno ",
        medida2: "60 ml",
        ingrediente3: "Jugo de limon ",
        medida3: "10 ml",
        ingrediente4: "Almibar simple  ",
        medida4: "10 ml",
        ingrediente5: "Decoración ",
        medida5: "Rama de tomillo fresco",
        ingrediente6: "Metodo de preparación ",
        medida6: "Batido",
        imagen: "img/tragos/LunadeMiel.jpg",

        isHidden: false,
        hover: false,
      },
      LaPuertadeAnana: {
        titulo: "La puerta de Anana",
        ingrediente1: "Americano Gancia ",
        medida1: "40 ml",
        ingrediente2: "Pineral ",
        medida2: "20 ml",
        ingrediente3: "Gin Bombay ",
        medida3: "20 ml",
        ingrediente4: "Decoración",
        medida4: "Cuadrado de Anana fresca",
        ingrediente5: "Metodo de preparación  ",
        medida5: "Refrescasdo",
        ingrediente6: "",
        medida6: "",
        imagen: "img/tragos/LapuertadeAnana.jpg",

        isHidden: false,
        hover: false,
      },
      MarRojo: {
        titulo: "Mar Rojo",
        ingrediente1: "Gancia Frutos Rojos",
        medida1: "45 ml",
        ingrediente2: "Jugo de sandia ",
        medida2: "45 ml",
        ingrediente3: "Sweet & Sour",
        medida3: "15 ml",
        ingrediente4: "Metodo de preparación",
        medida4: "Batido",
        ingrediente5: " ",
        medida5: "",
        ingrediente6: "",
        medida6: "",
        imagen: "img/tragos/MarRojo.jpg",

        isHidden: false,
        hover: false,
      },
      AmanecerenlaFruta: {
        titulo: "AMANECE EN LA FRUTA",
        ingrediente1: "Gancia Americano",
        medida1: "250 ml",
        ingrediente2: "Gaseosa Lima Limon",
        medida2: "600 ml",
        ingrediente3: "Sweet & Sour",
        medida3: "50 ml",
        ingrediente4: "Duranzno en medias lunas finas",
        medida4: "1 entero",
        ingrediente5: " Ciruelas en medias lunas finas",
        medida5: "2 entero",
        ingrediente6: "Limon en medias lunas finas",
        medida6: "1 entero",
        imagen: "img/tragos/AmanecerenlaFruta.jpg",

        isHidden: false,
        hover: false,
      },
    };
  },
};
</script>
<style >
</style>