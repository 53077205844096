<template>
  <div>
    <header-tragos></header-tragos>
    <cokteles></cokteles>

    <!--<test></test>-->
  </div>
</template>
<script>
import HeaderTragos from "./components/HeaderTragos";
import Cokteles from "./components/Cokteles.vue";

//import MainNavbar from "../layout/MainNavbar.vue";
//import MainFooter from "../layout/MainFooter.vue";

export default {
  name: "tragos",
  bodyClass: "tragos-page",
  components: {
    // MainNavbar,
    HeaderTragos,
    Cokteles,
  },
};
</script>
<style>
</style>