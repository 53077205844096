<template id="producto">
  <div>
    <div>
      <div class="prod-col">
        <div
          class="col-lg-4 productos-back"
          style="background-image: url('img/prod1.jpg'); display: flex"
          @mouseover="hover = true"
          @mouseleave="hover = false"
          v-on:click="isHidden = true"
        >
          <div class="o-m circle" id="circle" v-if="!isHidden"></div>

          <transition name="fade">
            <div
              v-if="hover"
              style="
                background-color: rgba(0, 0, 0, 0.7);
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
              "
              class="animate"
            >
              <p v-if="hover" class="text-product" name="hover">LIMA LIMÓN</p>
              <p class="description-product">
                <b> Combina lo mejor de Gancia Americano y Lima Limon. </b>Un
                coctel gasificado y listo para tomar en todos esos momentos
                especiales.
              </p>
            </div>
          </transition>
        </div>
        <div
          class="col-lg-4 productos-back"
          style="background-image: url('img/prod2.1.jpg')"
          @mouseover="hover2 = true"
          @mouseleave="hover2 = false"
          v-on:click="isHidden2 = true"
        >
          <div class="o-m circle" id="circle" v-if="!isHidden2"></div>
          <transition name="fade">
            <div
              v-if="hover2"
              style="
                background-color: rgba(0, 0, 0, 0.7);
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
              "
              class="animate"
            >
              <p v-if="hover2" class="text-product" name="hover2">AMERICANO</p>
              <p class="description-product">
                Su valor reside en la versatilidad para conformar
                <b>
                  diferentes cocteles y adaptarse a distintos momentos y
                  ocaciones.
                </b>
              </p>
            </div>
          </transition>
          <!-- Tabs with Background on Card -->

          <!-- End Tabs on plain Card -->
        </div>
        <div
          class="col-lg-4 productos-back"
          style="background-image: url('img/prod3.jpg')"
          @mouseover="hover3 = true"
          @mouseleave="hover3 = false"
          v-on:click="isHidden3 = true"
        >
          <div class="o-m circle" id="circle" v-if="!isHidden3"></div>

          <transition name="fade">
            <div
              v-if="hover3"
              style="
                background-color: rgba(0, 0, 0, 0.7);
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
              "
              class="animate"
            >
              <p v-if="hover3" class="text-product" name="hover3">
                FRUTOS ROJOS
              </p>
              <p class="description-product">
                Aperitivo infusionado a base de vino blanco con un delicioso
                sabor a Frutos Rojos.
                <b> Posee un agradable dulzor, muy refrescante, </b>ideal para
                disfrutar con hielo en las tardes de verano.
              </p>
            </div>
          </transition>
          <!-- Tabs with Background on Card -->

          <!-- End Tabs on plain Card -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hover: false,
      hover2: false,
      hover3: false,
      isHidden: false,
      isHidden2: false,
      isHidden3: false,
    };
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
