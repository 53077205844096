<template>
  <div>
    <!--es posible sacar el b-modal solo tengo que ver el tema del scroll-->

    <div class="log col-12" style="background-image: url('img/video.jpg')">
      <div class="soon">
        <h2>BIENVENIDO/A</h2>
        <h5>¿Sos mayor de 18 años?</h5>
        <transition name="fade">
          <div class="btn-cont">
            <div class="derecho">
              <a href="https://gancia.camonapp.com/gancia360/">Sí</a>
            </div>
            <div class="izquierdo">
              <a href="https://www.google.com/">No</a>
            </div>
          </div>
        </transition>

        <div class="cont-img">
          <img src="gancia.svg" alt="logo" />
        </div>
      </div>
      <div class="soon soon2">
        <p class="copy">
          BEBER CON MODERACION. PROHIBIDA SU VENTA A MENORES DE 18 AÑOS
          APERITIVOS. PARA MÁS INFORMACIÓN CONSULTE GANCIA.COM.AR
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    focusMyElement() {
      this.$refs.focusThis.focus();
    },
    hideModal() {
      this.$root.$emit("bv::hide::modal", "mymodal", "#btnShow");
    },
  },
};
</script>
<style>
/*popup*/

.log {
  height: 100vh;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  text-align: center;
  display: flex;
  align-items: space-around;
  justify-content: center;
  flex-direction: column;
}

.log h2 {
  font-size: 70px;
  color: #fff;
}
.log h5 {
  font-size: 35px;
  color: #fff;
  font-family: "lato";
  font-weight: 600;
}

.btn-cont {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.log a {
  color: #fff !important;
  font-family: "lato";
  font-weight: 600;
  font-size: 25px;
  padding: 10px 100px;
  border: solid 1px #fff;
  transition-duration: 1s;
}

.log a:hover {
  color: #fff;
  font-family: "lato";
  font-weight: 600;
  font-size: 25px;
  padding: 10px 70px;
  border: solid 1px #df3e3e;
  text-decoration: none;
  background-color: #df3e3e;
  transition-duration: 1s;
  cursor: pointer;
}

.log .derecho {
  margin-right: 10px;
}
.log .izquierdo {
  margin-left: 10px;
}

.log .cont-img {
  padding-top: 100px;
}
.log img {
  width: 150px;
}

.log .copy {
  font-size: 12px;
  background: #afaba0;
  color: #fff;
  font-family: "lato";
  font-weight: 300;
  margin-top: 50px;
}

.soon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
}
@media (max-width: 767px) {
  .log h2 {
    font-size: 40px;
    color: #fff;
  }
  .log h5 {
    font-size: 25px;
    color: #fff;
    font-family: "lato";
    font-weight: 600;
  }

  .btn-cont {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .log a {
    color: #fff;
    font-family: "lato";
    font-weight: 600;
    font-size: 20px;
    padding: 10px 50px;
    border: solid 1px #fff;
    transition-duration: 1s;
  }

  .log a:hover {
    color: #fff;
    font-family: "lato";
    font-weight: 600;
    font-size: 25px;
    padding: 10px 60px;
    border: solid 1px #df3e3e;
    text-decoration: none;
    background-color: #df3e3e;
    transition-duration: 1s;
  }
  .log .copy {
    font-size: 10px;
    background: #afaba0;
    color: #fff;
    font-family: "lato";
    font-weight: 300;
  }
}

.log .soon2 {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 20px;
}
.inicio {
  width: 100%;
  height: 100vh;
  margin: 0px;
  padding: 0px;
}

.modal-content .modal-body {
  padding-top: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  line-height: 0px !important;
  width: 100%;
  height: 100vh;
}

.modal-content .modal-header {
  border-bottom: none;
  padding-top: 0px !important;
  padding-right: 0px;
  padding-bottom: 0 !important;
  padding-left: 0px !important;
}
.close {
  display: none;
}

@media (min-width: 0px) {
  .modal-dialog {
    max-width: 100%;
    margin: 0;
  }
}
</style>
